<template>
  <div>
    <h2 class="mb-4">Composition d’un alliage: Constantes</h2>
    <v-form>
      <!-- Constants Table -->
      <v-textarea
        v-model="inputs.constants_table_caption"
        label="Légende"
        outlined
        rows="1"
        class="mb-n3"
        placeholder="Rédigez une légende pour le tableau des constantes ici."
      />
      <v-simple-table>
        <thead>
          <tr>
            <td>&nbsp;</td>
            <td style="font-weight: bold; text-align: center">Valeur</td>
            <td style="font-weight: bold; text-align: center">Unités</td>
            <td style="font-weight: bold; text-align: center">Référence</td>
          </tr>
        </thead>
        <tr v-for="{key, label} in constants" :key="key">
          <td class="font-weight: bold">{{ label }}</td>
          <td><calculation-input v-model="inputs[`constants_${key}`]" class="centered-input" /></td>
          <td>
            <v-select
              v-model="inputs[`constants_${key}_units`]"
              :items="units"
              item-value="key"
              outlined
              hide-details
            >
              <template #item="{item}">
                <stemble-latex :content="item.label" />
              </template>
              <template #selection="{item}">
                <stemble-latex class="no-text-transform" :content="item.label" />
              </template>
            </v-select>
          </td>
          <td>
            <v-text-field v-model="inputs[`constants_${key}_reference`]" outlined hide-details />
          </td>
        </tr>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'OttawaLab1ReportConstantsTable',
  components: {StembleLatex, CalculationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        constants_table_caption: null,
        constants_molarMassZn: null,
        constants_molarMassMg: null,
        constants_molarMassAl: null,
        constants_densityOfWater: null,
        constants_idealGasConstant: null,
        constants_molarMassZn_units: null,
        constants_molarMassMg_units: null,
        constants_molarMassAl_units: null,
        constants_densityOfWater_units: null,
        constants_idealGasConstant_units: null,
        constants_molarMassZn_reference: null,
        constants_molarMassMg_reference: null,
        constants_molarMassAl_reference: null,
        constants_densityOfWater_reference: null,
        constants_idealGasConstant_reference: null,
        constants_molarMassZn_units_reference: null,
        constants_molarMassMg_units_reference: null,
        constants_molarMassAl_units_reference: null,
        constants_densityOfWater_units_reference: null,
        constants_idealGasConstant_units_reference: null,
      },
      constants: [
        {key: 'molarMassZn', label: 'Masse molaire du zinc'},
        {key: 'molarMassMg', label: 'Masse molaire du magnésium'},
        {key: 'molarMassAl', label: 'Masse molaire de l’aluminium'},
        {key: 'densityOfWater', label: 'Densité de l’eau'},
        {key: 'idealGasConstant', label: 'Constante universelle des gaz parfaits'},
      ],
      units: [
        {key: 'g', label: '$\\text{g}$'},
        {key: 'kPa', label: '$\\text{kPa}$'},
        {key: 'mL', label: '$\\text{mL}$'},
        {key: 'gPerMol', label: '$\\text{g} \\cdot \\text{mol}^{-1}$'},
        {key: 'degreesCelsius', label: '$^\\omicron\\text{C}$'},
        {key: 'gramsPerMillilitre', label: '$\\text{g} \\cdot \\text{mL}^{-1}$'},
        {
          key: 'joulesPerKelvinPerMole',
          label: '$\\text{J} \\cdot \\text{K}^{-1} \\cdot \\text{mol}^{-1}$',
        },
        {
          key: 'moles',
          label: '$\\text{mol}$',
        },
        {
          key: 'percent',
          label: '$\\text{\\%}$',
        },
      ],
    };
  },
};
</script>
